<template>
    <div class="percentRingBox">
		<div class="textBox">
			<div class="divideBar">
				<img src="@/assets/page1/Group1321314657.png" alt="" style="width: 100%; height: 100%">
			</div>
			<active-number class="ringValue" :number="events"></active-number>
			<span class="ringName">{{itemName}}</span>
		</div>
		<div class="ringBox">
			<img src="@/assets/page1/ringLayer2x.png" class="ringLayer" alt="">
			<span class="percentValue" :style="{color:ringColor}">{{content3}}%</span>
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100%" viewBox="0 0 160 160" class="ringSvg" style="position: absolute">
				<circle cx="80" cy="80" r="70" stroke-width="20" fill="none" :stroke="ringColor" class="ringCircle" :style="{strokeDashoffset:content2}"></circle>
			</svg>
		</div>
		
	</div>
</template>

<style lang="less" scoped>
.percentRingBox {
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	.ringBox {
		height: 100%;
		aspect-ratio: 1/1;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		
		.ringLayer {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.percentValue {
			color: #fff;
			font-size: 1.85vh;
			line-height: 2.78vh;
			vertical-align: top;
			text-shadow: 0px 0px 9px rgba(255,208,59,0.7);
			font-family: 'titleFont', sans-serif;
			text-shadow: 0px 0px 10% rgba(255,208,59,0.7);
		}

		.ringSvg {
			position: absolute;
		}

		.ringCircle {
			stroke-dasharray: 439.82; /*2πr, r=70*/
			stroke-linecap: round;
			transition: stroke-dashoffset 1.2s;
		}

	}
	.textBox {
		width: 49.13%;
		height: 66.25%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		margin-right: 4.62%;
		
		.devideBar {
			width: 100%;
			height: 22.64%;
		}

		.ringName {
			position: absolute;
			bottom: 0;
			left: 0;
			color: rgba(255, 255, 255, 0.7);
			font-family: 'pingfangSc', sans-serif;
			font-size: 1.3vh;
			line-height: 2.5vh;
		}
		.ringValue {
			position: absolute;
			top: 0;
			left: 0;
			font-family: 'titleFont', sans-serif;
			font-size: 2.22vh;
			line-height: 2.5vh;
			background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
}

</style>

<script>
const activeNumber = () => import('@/views/components/activeNumber.vue');

export default {
	props:['itemName','percent','ringColor','events'],
	components:{
		activeNumber
	},
	data(){
		return {
			content2: 439.82,
			content3: 0,
		}
	},
	mounted(){	
		this.setPercentage(this.percent);
		return false;
	},
	methods:{
		setPercentage(p){
			let percentage = parseInt(p);
			this.content2 = 439.82 - 439.82*percentage/100; 
			this.content3 = percentage;
		}
	},
	watch:{
		percent(p){
			this.setPercentage(p);
		}
	}
}
</script>
